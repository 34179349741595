.dealInner {
    width: 100%;
    height: 100%;

    &__info {
        position: relative;
        width: 350px;
        height: 100%;
        border-right: 1px solid #fff;

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            padding: 20px 24px;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
        }

        &Short {
            width: 100%;
            margin-bottom: 12px;
        }

        &Responsible {
            width: 100%;
            margin-top: 12px;
        }

        &Actions {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            padding: 20px 24px;
            background: linear-gradient(to top, $colorBlueBack calc(100% - 20px), rgba($colorBlueBack, 0));
            pointer-events: none;

            &Button {
                width: 100%;
                pointer-events: visible;
            }

            &Error {
                width: 100%;
                transition: $trans;

                &:not(._empty) {
                    margin-top: 10px;
                }
            }
        }
    }

    &__short {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 18px 24px;
        overflow: hidden;
        background: #fff;
        border-radius: 18px;

        &Inner {
            position: relative;
            width: 100%;
        }

        &Color {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 14px;
            height: 14px;
            border-radius: 0 0 8px;
            transition: $trans;
        }

        &Title {
            margin-bottom: 2px;
            padding-right: 110px;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.3;
            transition: $trans;
        }

        &StepBtn {
            position: absolute;
            top: 2px;
            right: 0;
            color: $colorMain;
            font-size: 13px;
            line-height: 1.3;

            @include button(0.97);
        }

        &Description {
            max-width: 100%;
            margin-bottom: 7px;
            color: #75808b;
            font-size: 13px;
            line-height: 1.3;
        }

        &Steps {
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 11px;
        }

        &Step {
            position: relative;
            height: 6px;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: rgba($colorGreyDark, 0.32);
                transform: translate(0, -50%);
                content: "";
            }

            &Inner {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 3px;
                border-radius: 360px;
                transform: translate(0, -50%);
                transition: $trans;
            }

            &:not(._current) &Inner {
                opacity: 0;
            }
        }

        &Tags {
            width: 100%;
            margin-bottom: -3px;

            &Support {
                width: 38px;
                padding-top: 1px;
                color: $colorDark2;
                font-size: 13px;
                line-height: 1.2;
            }

            &Items {
                width: calc(100% - 38px);

                &._static {
                    display: flex;
                    flex-flow: row wrap;
                }
            }

            &Item {
                width: auto;
                margin-top: -1px;

                &Empty {
                    margin-left: -3px;
                    padding-top: 1px;
                    color: $colorGreyDark;
                    font-size: 13px;
                    line-height: 1.2;
                }
            }

            &Items._static &Item {
                margin: 0 3px 3px 0;
            }
        }
    }

    &__alert {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 4px 10px;
        color: $colorDark2;
        font-size: 13.5px;
        white-space: nowrap;
        background: #fff27d;
        border-radius: 12px;

        & b {
            font-weight: 600;
        }

        &Icon {
            width: 18px;
            height: 18px;
            margin-right: 7px;

            @include icon(#fff);

            & .icon__theme {
                fill: $colorDark2 !important;
            }
        }

        &Items {
            width: 100%;
            transition: $trans;

            &:not(._empty) {
                margin-bottom: 12px;
            }
        }

        &Item {
            width: 100%;
        }

        &Items._static &Item:not(._last) {
            margin-bottom: 6px;
        }
    }

    &__main {
        position: relative;
        width: 100%;
        background: #fff;
        border-radius: 18px;

        &Head {
            align-items: center;
            width: 100%;
            padding: 18px 24px;
            border-bottom: 1px solid rgba($colorGreyDark, 0.2);

            &Avatar {
                width: 46px;
                height: 46px;
                border-radius: 50%;
                transition: 0.3s ease-in-out background;

                & .v2avatar {
                    background: none;
                }

                & .v2avatar__text {
                    color: inherit;
                    transition: none;
                }
            }

            &Content {
                width: calc(100% - 46px);
                padding-left: 14px;
            }

            &Title {
                margin-bottom: 2px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.3;
            }

            &Description {
                color: $colorGreyDark;
                font-size: 13px;
                line-height: 1.3;
            }
        }

        &Content {
            width: 100%;
            padding: 18px 24px;
        }

        & .v2widgetField__support {
            color: #75808b;
        }

        &Card {
            width: 100%;
        }

        &CustomFields {
            width: 100%;
            margin-top: 14px;
        }

        &CustomField {
            width: 100%;

            & + & {
                margin-top: 14px;
            }
        }
    }

    &__responsible {
        position: relative;
        width: 100%;
        padding: 20px 40px 20px 20px;
        background: #fff;
        border-radius: 18px;

        &More {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 2;
            width: 20px;
            height: 20px;
        }

        &Inner {
            position: relative;
            width: 100%;
        }

        &User {
            position: relative;
            width: 100%;

            &:not(._empty) {
                padding-left: 52px;
            }
        }

        &Avatar {
            position: absolute;
            top: 50%;
            left: 0;
            width: 38px;
            height: 38px;
            transform: translate(0, -50%);

            & .v2avatar__text {
                font-size: 14px;
            }
        }

        &Title {
            margin-bottom: 3px;
            color: $colorDark2;
            font-size: 15px;
            line-height: 1.3;
        }

        &Support {
            color: $colorGreyDark;
            font-size: 14px;
            line-height: 1.2;
        }
    }

    &__logs {
        width: calc(100% - 350px);
        height: 100%;
        background: rgba(#eaeff6, 0.72);
    }
}
