.v2table {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__inner {
        position: relative;
        width: 100%;
        min-height: 100%;
    }

    &__scroll {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        transition: $trans;
    }

    &._isLoading &__content {
        opacity: 0;
    }

    &._wrapContent &__content {
        padding: 14px 0;
    }

    &__fake {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 26px;
        pointer-events: none;

        &Inner {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Item {
            min-width: 32px;

            & + & {
                margin-top: 4px;
            }
        }
    }

    &._withoutHead &__fake {
        padding-top: 0;
    }

    &._wrapContent &__fake {
        padding-top: 40px;
    }

    &__row {
        display: block;
        width: 100%;
        padding: 0 6px;
        transition: $trans;
        user-select: text !important;
        will-change: transform;

        &._link {
            user-select: text;
        }

        &._head {
            position: sticky;
            top: 0;
            z-index: 2;
            background: #eaeff6;
        }

        &:not(._head) {
            &:nth-child(2n + 1) {
                background: #fbfcfd;
            }
        }

        &:not(._head)::before,
        &:not(._head) &Inner::before {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 1px;
            background: #e7ecf1;
            opacity: 0;
            transition: $trans;
            content: "";
        }

        &._mediumColorBack:not(._active) {
            background: rgba($colorMedium, 0.04) !important;
        }

        &._active {
            background: rgba($colorMain, 0.05) !important;
        }

        &._errorColorBack:not(._active) {
            background: rgba($colorAlert, 0.03) !important;
        }

        &Inner {
            position: relative;
            align-items: stretch;
            width: 100%;
            height: 100%;
            transition: $trans;
            will-change: transform;
        }

        &._block &Inner {
            position: relative;
            z-index: 1;
            display: block;
            overflow: hidden;
        }

        &Block {
            align-items: stretch;
            width: 100%;
            min-height: 56px;
            padding: 15px 0;
            transition: $trans;

            & + & {
                border-top: 1px solid transparent;
            }
        }

        &._fake &Choice {
            @extend ._LOADERWAIT;

            box-shadow: none;

            &::before,
            &::after {
                display: none;
            }
        }

        &._choiceTop &Choice {
            top: 16px;
            transform: translate(0, 0);
        }

        &Choice {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            width: 28px;
            height: 28px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 0 2px 0 #d8dce4;
            transform: translate(0, -50%);

            @include forType("app") {
                width: 32px;
                height: 32px;
                border-radius: 9px;
            }

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                background: $colorMain;
                transform: translate(-50%, -50%);
                transition: $trans;
                content: "";
            }

            &::before {
                width: 10px;
                height: 1px;

                @include forType("app") {
                    width: 12px;
                }
            }

            &::after {
                width: 1px;
                height: 10px;

                @include forType("app") {
                    height: 12px;
                }
            }

            &._active::before {
                background: $colorAlert;
            }

            &._active::after {
                opacity: 0;
            }
        }
    }

    &._inFilter &__row::before,
    &._inFilter &__rowInner::before {
        background: $colorBlue;
    }

    &._inWidget &__row {
        padding-right: 23px;
        padding-left: 23px;
    }

    &._isList &__row {
        padding-right: 16px;
        padding-left: 16px;

        &Inner {
            padding-right: 40px;
        }
    }

    &._inWidget &__row._head {
        background: #f9fbfd;
    }

    &._inWidget &__row:not(._head) {
        background: none;
    }

    &._inWidget:not(._rowBlock) &__row:not(._head) &__rowInner {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &._isList:not(._rowBlock) &__row:not(._head) &__rowInner {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &._inWidget &__row:not(._head, :last-child) &__rowInner::before {
        opacity: 1;
    }

    &:not(._hasScroll, ._wrapContent) &__row:not(._head, ._notBottomBorder):last-child::before {
        opacity: 1;
    }

    &._blockSkinny &__rowBlock {
        padding: 0;
    }

    &._wrapContent &__row {
        padding-right: 22px;
        padding-left: 22px;
    }

    &._wrapContent &__row:not(._head) {
        padding-top: 3px;
        padding-bottom: 3px;
        background: none !important;
    }

    &._wrapContent &__row:not(._head) &__rowInner {
        background: #fff;
        border-radius: 14px;
        box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);
    }

    &._rowHeight._wrapContent &__col {
        padding-right: 14px;
        padding-left: 14px;

        &:first-child {
            padding-left: 18px;
        }

        &:last-child {
            padding-right: 18px;
        }
    }

    &._rowHeight:not(._rowBlock) &__row:not(._head) &__rowInner {
        padding: 14px 0;
    }

    &._rowHeight &__row:not(._head) &__col {
        min-height: auto;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    &._rowBlock._wrapContent &__row:not(._head) &__col {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    &__row._colCenter &__col {
        justify-content: center;
    }

    &:not(._rowBlock, ._wrapContent, ._inWidget) &__row:not(._head) &__col {
        min-height: 56px;
    }

    &__col {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 10px;
        color: $colorDark2;
        font-size: 14px;
        line-height: 1.3;

        & + & {
            border-left: 1px solid #e7ecf1;
        }

        &Inner {
            max-width: 100%;
            line-height: 1.3;
        }

        &._innerRow &Inner {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &._innerCol &Inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &._full &Inner {
            width: 100%;
        }

        &._hidden &Inner {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &._withMore {
            padding-right: 30px !important;
        }
    }

    &._pays &__col._amount {
        padding-right: 4px !important;
    }

    &__row._head &__col {
        padding-top: 4px;
        font-weight: 600;
        font-size: 13px;
        border-color: #fff;
    }

    &._inWidget &__row:not(._head) &__col {
        padding-top: 6px;
        padding-bottom: 6px;

        @include forType("app") {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &._inWidget &__row._head &__col {
        border-color: $colorBlue;
    }

    &._inWidget &__col {
        &:first-child {
            padding-left: 0 !important;
        }

        &:last-child:not(._withMore) {
            padding-right: 0 !important;
        }
    }

    &._contentBlog &__row:not(._head) &__col._preview,
    &._contentAnnounce &__row:not(._head) &__col._preview,
    &._contentLegal &__row:not(._head) &__col._preview,
    &._contentUsersKnowledge &__row:not(._head) &__col._preview,
    &._contentClientsKnowledge &__row:not(._head) &__col._preview {
        margin: -14px 0;

        &._withContent {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__row:not(._head) &__col {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &__row._active &__previewAvatar._greyBack {
        background: #fff;

        @include icon($colorMain);
    }

    &__preview {
        width: 100%;

        & + & {
            margin-left: 4px;
        }

        &._hiddenAvatar {
            margin: -8px 0;
        }

        &._mediumSize._hiddenAvatar {
            margin: -4px 0;
        }

        &._minSize._hiddenAvatar {
            margin: -2px 0;
        }

        &Avatar {
            width: 32px;
            height: 32px;
            margin-top: 1px;
            transition: $trans;

            &._greyBack {
                background: $colorBlueBack;
                border-radius: 50%;
            }

            & .v2avatar__text {
                font-size: 14px;
            }

            &Icon {
                width: 100%;
                height: 100%;

                @include icon($colorGreyDark);
            }
        }

        &._mediumSize &Avatar {
            width: 28px;
            height: 28px;
        }

        &._minSize &Avatar {
            width: 22px;
            height: 22px;
        }

        &Info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 32px);
            min-height: 32px;
            padding-left: 8px;

            &._top {
                padding-top: 6px;
            }

            & ._choiceWide {
                display: block;
                width: calc(100% + 40px);
                max-width: none;
            }

            &Inner {
                width: 100%;
            }
        }

        &._mediumSize &Info {
            min-height: 28px;
        }

        &._minSize &Info {
            min-height: 22px;
        }
    }

    &__logo {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 28px;
        height: 28px;
        overflow: hidden;
        border-radius: 5px;
    }

    &__text {
        display: inline;
        max-width: 100%;
        color: $colorDark2;
        font-size: 14px;
        line-height: 1.3;

        @include forType("app") {
            font-size: 15px;
        }

        &:not(._dynamic) {
            position: relative;
        }

        &._fullWidth {
            width: 100%;
        }

        &._chat {
            padding: 2px 0 2px 32px;
        }

        &._block {
            display: block;
        }

        &._wrapWord {
            overflow-wrap: break-word;
        }

        &:not(._link, ._CLICK) {
            cursor: default;
        }

        &._link:not(._empty) {
            color: $colorMain;
            border-bottom: 1px dashed $colorMain;
            user-select: text !important;

            &._full {
                color: $colorDark2;
                border-bottom-style: solid;
            }
        }

        &._grey {
            color: $colorGreyDark;
        }

        &._lightWeight {
            font-weight: 300;
        }

        &._mediumWeight {
            font-weight: 600;
        }

        &._mediumColor {
            color: #ffb400;
        }

        &._processColor {
            color: #98d400;
        }

        &._alertColor {
            color: $colorAlert;
        }

        &._preSuccessColor {
            color: #5cc23f;
        }

        &._successColor {
            color: $colorSuccess;
        }

        &._errorColor {
            color: $colorError;
        }

        &._mainColor {
            color: $colorMain;
        }

        &._top {
            margin-top: 4px;
        }

        &._bottom {
            margin-bottom: 4px;
        }

        & b {
            font-weight: 600;
        }

        & span {
            &._beauty {
                color: $colorMain;
                font-weight: 600;
                background: rgba($colorMain, 0.1);
            }
        }

        & span._link {
            color: $colorMain;
        }

        &._loading {
            padding-right: 24px;
        }

        &._minSize {
            font-size: 13px;
        }

        &._bigSize {
            font-weight: 600;
            font-size: 17px;

            &._bottom {
                // margin-bottom: 7px;
            }
        }

        &Chat {
            position: absolute;
            top: 50%;
            left: 0;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: $colorBlueActive;
            border-radius: 6px;
            transform: translate(0, -50%);
            transition: $trans;
            will-change: transform;

            &:active {
                transform: translate(0, -50%) scale(0.94);
            }

            &Icon {
                display: block;
                width: 14px;
                height: 14px;
                margin-left: -1px;

                @include icon($colorMain);
            }
        }

        &Loader {
            position: absolute;
            top: 50%;
            right: 0;
            width: 16px;
            height: 16px;
            transform: translate(0, -50%);
        }

        &Alert {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 3px;
            transform: translate(0, 1px);
        }

        &Info {
            display: inline-block;
            width: 14px;
            height: 14px;
            transform: translate(0, 2px);

            @include icon(rgba($colorMain, 0.24));

            &._grey {
                @include icon(rgba($colorGreyDark, 0.32));
            }
        }

        &Icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 3px;

            &._article {
                transform: translate(-2px, 4px);
            }
        }

        &._successColor &Info {
            @include icon(rgba($colorSuccess, 0.32));
        }

        &._errorColor &Info {
            @include icon(rgba($colorAlert, 0.32));
        }
    }

    ._modelLogs &__text b {
        padding: 0 4px;
        background: $colorBlueBack;
        border-radius: 6px;
    }

    &._inFilter .manualCorporationInfo__logo {
        background: #fff;
    }

    &__corporation {
        position: relative;
        max-width: 100%;

        &._top {
            margin-top: 4px;
        }

        & + & {
            margin-top: 8px;
        }

        &._list {
            padding-right: 12px;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                border: 2px solid transparent;
                border-right-color: $colorDark2;
                border-bottom-color: $colorDark2;
                transform: translate(0, -50%) rotate(45deg);
                transition: $trans;
                content: "";
            }
        }
    }

    &__row._blockShow &__rowBlock {
        border-color: $colorBlue;
    }

    &__row._blockShow &__rowBlock._main &__corporation .manualCorporationInfo__name {
        color: $colorMain;
    }

    &__row._blockShow &__corporation {
        &::after {
            margin-top: 2px;
            transform: translate(0, -50%) rotate(-135deg);
        }
    }

    &__status {
        position: relative;
        color: $colorMedium;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;

        &._default {
            color: $colorDark2;
            font-weight: 500;
        }

        &._process {
            color: #98d400;

            @include icon(rgba(#98d400, 0.32));
        }

        &._success {
            color: $colorSuccess;

            @include icon(rgba($colorSuccess, 0.32));
        }

        &._alert {
            color: $colorAlert;

            @include icon(rgba($colorAlert, 0.2));
        }

        &._wait {
            color: $colorGreyDark;

            @include icon(rgba($colorGreyDark, 0.32));
        }

        &._block {
            z-index: 1;
            max-width: 100%;
            padding: 2px 5px;
            overflow: hidden;
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            white-space: nowrap;
            text-transform: uppercase;
            text-overflow: ellipsis;
            background: $colorMedium;
            border-radius: 6px;

            &._withInfo {
                padding-right: 18px;
            }

            &._new {
                background: $colorMain;
            }

            &._active {
                background: $colorSuccess;
            }

            &._process {
                background: #98d400;
            }

            &._alert {
                background: $colorAlert;
            }

            &._wait {
                background: $colorGreyDark;
            }

            &._join-sms {
                background: #f29138;
            }

            &._join-invite {
                background: #c2c2c2;
            }

            &._join-anket,
            &._join-docs {
                background: #ffb400;
            }

            &._join-card,
            &._join-check {
                background: #98d400;
            }

            &._join-reject {
                background: #fb927d;
            }

            &._join-complete {
                background: $colorSuccess;
            }

            &._join-inactive {
                background: $colorAlert;
            }
        }

        &Inner {
            font-weight: 600;
            line-height: 1.3;
        }

        &._default &Inner {
            font-weight: 500;
        }

        & span {
            font-weight: 500;
        }

        &._block._withInfo &Info {
            right: 4px;
            left: auto;
            width: 10px;
            height: 10px;
            margin: 0;

            @include icon(rgba(#fff, 0.32));
        }

        &Info {
            position: absolute;
            top: 50%;
            left: 100%;
            width: 12px;
            height: 12px;
            margin: 1px 0 0 4px;
            transform: translate(0, -50%);

            @include icon(rgba($colorMain, 0.2));
        }
    }

    &__executorOrg {
        display: inline-block;
        transform: translate(0, -1px);
    }

    &__opf {
        display: inline-block;
        max-width: 100%;
        font-size: 14px;

        &._error {
            position: relative;
            padding: 0 6px;
            color: $colorAlert;

            &::after {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: -1;
                width: 100%;
                height: calc(100% + 6px);
                background: rgba($colorAlert, 0.14);
                border-radius: 7px;
                transform: translate(0, -50%);
                content: "";
            }

            &::before {
                position: absolute;
                top: -8px;
                right: -2px;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10px;
                height: 10px;
                color: #fff;
                font-weight: 600;
                font-size: 9px;
                text-align: center;
                background: $colorAlert;
                border-radius: 50%;
                content: "!";
            }
        }
    }

    &__alert {
        position: relative;
        margin-top: 5px;
        padding-left: 17px;
        color: #ff9e3f;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.15;

        &._minSize {
            font-size: 13px;
        }

        &._alarm {
            color: $colorAlert;
        }

        &Icon {
            position: absolute;
            top: 2px;
            left: 0;
            width: 12px;
            height: 12px;

            @include icon(#ff9e3f);
        }

        &._alarm &Icon {
            @include icon($colorAlert);
        }

        &._minSize &Icon {
            top: 1px;
        }
    }

    &._wrapContent &__more {
        top: 0;
        right: 5px;
    }

    &__more {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 24px;
        height: 24px;
        transition: $trans;

        @include icon($colorGreyDark);

        &._hide {
            opacity: 0;
        }

        &._fullRight {
            top: 0;
            right: -3px;
        }

        &._vertCenter {
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &__row:hover &__more._hide {
        opacity: 1;
    }

    &__comment {
        & + & {
            margin-top: 4px;
        }
    }

    &__proxy {
        &Status {
            color: $colorGreyDark;
            font-size: 14px;
            line-height: 1.3;

            &._required {
                font-weight: 600;
            }

            &._wait {
                color: $colorDark2;
            }

            &._corporationSign,
            &._executorSign,
            &._process {
                color: $colorMedium;
                font-weight: 600;
            }

            &._inactive {
                color: #f29138;
                font-weight: 600;
            }

            &._complete {
                color: $colorSuccess;
                font-weight: 600;
            }
        }

        &Description {
            max-width: 100%;
            margin-top: 3px;
            color: $colorGreyDark;
            font-size: 13px;
            line-height: 1.3;
        }

        &Button {
            margin-top: 6px;
        }
    }

    &__button {
        position: relative;
        align-items: center;
        justify-content: center;
        height: 23px;
        padding: 3px 7px;
        color: $colorMain;
        font-size: 13px;
        background: #f3f6ff;
        border-radius: 7px;
        transition: $trans;

        &._mediumWeight {
            font-weight: 600;
        }

        &._left {
            margin-top: -4px;
            margin-bottom: -4px;
            margin-left: 12px;
        }

        @include button(0.97);

        &._main {
            color: #fff;
            background: $colorMain;

            // box-shadow: 0 2px 4px 0 rgba(62, 106, 237, 0.56);
        }

        &._alert {
            color: $colorAlert;
            background: $colorAlertBack;
        }

        &._top {
            margin-top: 6px;
        }

        &Loader {
            background: inherit;
            border-radius: 6px;

            @include loader($colorMain);

            & ._LOADERITEM {
                width: 14px;
                height: 14px;
            }
        }

        &Icon {
            width: 9px;
            height: 12px;
            margin-left: 4px;

            @include icon($colorMain);
        }

        &._fns &Icon {
            width: 12px;
        }
    }

    &__checkbox {
        position: relative;
        width: 100%;

        &._vertical {
            padding-left: 24px;
        }

        &Icon {
            position: absolute;
            top: 0;
            left: 22px;
            width: 15px;
            height: 15px;
            object-fit: contain;
            cursor: pointer;

            &._static {
                position: relative;
                left: 0;
                display: inline-block;
                margin-left: 4px;
                transform: translate(0, 3px);
            }
        }

        &Box {
            margin-bottom: 4px;
        }

        &._vertical &Box {
            position: absolute;
            top: 0;
            left: 0;
        }

        &Executor {
            display: inline-block;
            margin-top: 4px;
        }
    }

    &__tax {
        position: relative;
        align-items: center;
        justify-content: center;
        height: 23px;
        margin: 2px 0 3px;
        padding: 3px 7px;
        color: $colorMain;
        font-size: 13px;
        background: $colorBlueBack;
        border-radius: 6px;

        &Icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;

            @include icon($colorMain);
        }
    }

    &__progress {
        width: 100%;
        padding-top: 2px;

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 3px;
            margin-bottom: 6px;
            overflow: hidden;
            background: #f25130;
            background: $colorBlue;
            border-radius: 360px;
        }

        &._complete &Inner {
            background: $colorSuccess;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-right: 1px solid #fff;

            // border-right-color: #fff;
            &Inner {
                width: 100%;
                height: 100%;
                background: #cee100;
                border-radius: 360px 0 0 360px;
            }

            &._error &Inner {
                background: $colorAlert;
                border-radius: 0;
            }
        }

        &._full &Item._error &ItemInner {
            border-radius: 0 360px 360px 0;
        }

        &._complete &Item {
            border-right: 0;

            &Inner {
                background: none;
            }
        }

        &Description {
            color: $colorDark2;
            font-weight: 500;
            font-size: 12px;
        }
    }

    &__switch {
        // padding-top: 10px;

        &._abs {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 2;
        }
    }

    &__color {
        width: 34px;
        height: 4px;
        border-radius: 360px;

        &._inline {
            display: inline-block;
            width: 30px;
            transform: translate(0, -2px);
        }
    }

    &__colorPin {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transform: translate(0, -2px);
    }

    &__mailing {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 12px 16px 14px;
        overflow: hidden;
        border-radius: 10px;
        transition: $trans;

        @include button(0.98);

        &._wait {
            background: #f1f5ff;
        }

        &._process {
            background: #fff;
            box-shadow: 0 0 20px rgba(#000, 0.1);
        }

        &._pause {
            background: #fff7eb;
        }

        &._completed {
            background: #ebffec;
        }

        &Title {
            position: relative;
            width: 100%;
            margin-bottom: 2px;
            white-space: nowrap;

            &Inner {
                color: $colorMain;
                font-weight: 600;
                font-size: 14.5px;
                line-height: 1.3;
            }
        }

        &Btn {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            align-items: center;
            justify-content: center;
            width: 12px;
            height: 12px;
            margin-top: 1px;
            background: $colorMain;
            border-radius: 50%;
            transform: translate(0, -50%);
            transition: $trans;
            will-change: transform;

            &._loading {
                background: none !important;
            }

            &Loader {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 3;
                width: 16px;
                height: 16px;
                transform: translate(-50%, -50%);
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 6px;
                height: 6px;
                transform: translate(-50%, -50%);
                transition: $trans;
                will-change: transform;

                @include icon(#fff);

                &:not(._show) {
                    opacity: 0;
                }
            }

            &._loading &Icon,
            &._loading &Progress {
                opacity: 0;
            }

            &._pause &Progress {
                background: rgba(#f29438, 0.2);

                & circle {
                    stroke: rgba(#f29438, 0.2);
                }
            }

            &Progress {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 20px;
                height: 20px;
                background: rgba($colorMain, 0.2);
                border-radius: 50%;
                transform: translate(-50%, -50%);
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }

                & svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    transform: rotate(270deg) rotateY(0deg);

                    & circle {
                        position: relative;
                        z-index: 2;
                        display: block;
                        width: 100%;
                        height: 100%;
                        transition: $trans;
                        fill: none;
                        stroke: rgba($colorMain, 0.2);
                        stroke-width: 5px;
                        r: calc(50% - 2.5px);
                        cx: 50%;
                        cy: 50%;
                    }
                }
            }
        }

        &._pause &Btn,
        &._wait &Btn {
            &Icon {
                margin-left: 0.5px;
            }
        }

        &._pause &Btn {
            background: #f29438;
        }

        &._completed &Btn {
            background: #5cc962;
        }

        &._pause &TitleInner {
            color: #f29438;
        }

        &._completed &TitleInner {
            color: #5cc962;
        }

        &Description {
            max-width: 100%;
            color: #75808b;
            font-size: 12.5px;
            line-height: 1.3;
            white-space: nowrap;
        }

        &Progress {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: #d4e0ff;
            opacity: 0;
            transition: $trans;

            &Line {
                height: 100%;
                background: $colorMain;
                transition: $trans;
            }
        }

        &._process &Progress,
        &._pause &Progress {
            opacity: 1;
        }

        &._pause &Progress {
            background: #ffdfae;

            &Line {
                background: #ffb33f;
            }
        }
    }

    &__tags {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% + 4px);
        margin-bottom: -4px;
    }

    &__tag {
        max-width: 100%;
        margin: 0 4px 4px 0;
    }

    &__role {
        position: relative;
        width: 100%;
        padding-left: 36px;

        &Icon {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 18px;
            height: 18px;

            @include icon($colorGreyDark);
        }

        &Switch {
            align-items: flex-end;
            width: 100%;
            white-space: nowrap;

            &Box {
                margin-bottom: 6px;
            }
        }

        &Section {
            width: 100%;

            &Title {
                position: relative;
                display: block;
            }

            &Actions {
                margin-top: 14px;
                padding-left: 24px;
            }

            &Action {
                position: relative;
                display: block;

                & + & {
                    margin-top: 12px;
                }
            }
        }
    }

    &._contentAnnounce &__imageIcon {
        margin-left: -8px;
    }

    &._contentLegal &__imageIcon {
        width: 26px;
        height: 26px;
    }

    &._contentUsersKnowledge &__imageIcon,
    &._contentClientsKnowledge &__imageIcon {
        width: 32px;
        height: 32px;
        filter: brightness(0) invert(1);
    }

    &__image {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        margin: -4px 0;
        overflow: hidden;
        border: 1px solid $colorBlue;
        border-radius: 10px;

        &._article {
            width: calc(100% - 6px);
            height: 70px;
            margin: 0 3px;
            border: 0;
            border-radius: 12px 2px 2px 12px;

            &._mainBack {
                background: $colorMain;
            }
        }

        &Icon {
            width: 32px;
            height: 32px;
        }

        &._mainBack &Icon {
            @include icon(#fff);
        }
    }

    &__step {
        position: relative;
        padding-left: 14px;
        font-size: 14px;
        line-height: 1.3;

        &Color {
            position: absolute;
            top: 7px;
            left: 0;
            z-index: 2;
            width: 5px;
            height: 5px;
            border-radius: 50%;
        }
    }

    &__colorLine {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5px;
        height: calc(100% - 10px);
        border-radius: 0 360px 360px 0;
        transform: translate(0, -50%);
    }

    &._deals &__col._name &__colorLine {
        // left: -18px;
    }

    &__audio {
        width: 100%;

        & .chatFile {
            padding: 0;
            background: none !important;
        }

        & .chatFile__audio {
            padding: 4px 0 4px 28px;
        }

        & .chatFile__audioButton {
            width: 20px;
            height: 20px;
        }

        & .chatFile__audioButtonIcon {
            width: 8px;
            height: 8px;

            &._play {
                margin-left: 0.5px;
            }
        }

        & .chatFile__audioProgressDuration {
            // display: none;
        }

        & .chatFile__audioProgress {
            width: 100%;
            min-width: auto;
            padding-right: 0;

            // display: none;
        }

        & .chatFile__audioProgressLine {
            background: #edf2f8;
        }

        & .chatFile__audioProgressLineActiveDot {
            width: 5px;
            height: 5px;
        }
    }
}
