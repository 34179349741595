.deal {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 1px 6px 0 rgba(#75808b, 0.1);
    transition: $trans;
    user-select: none;

    &._drag {
        opacity: 0.5;
    }

    &__delete {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(#fff, 0.9);
        border-radius: 20px;

        &Icon {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;

            @include icon($colorAlert);
        }

        &Text {
            color: $colorDark2;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.35;
            text-align: center;

            & b {
                font-weight: 500;
            }
        }
    }

    &__content {
        width: 100%;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(#c5cfda, 0.5);

        &Head {
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
        }

        &Title {
            position: relative;
            padding-left: 15px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;

            &Color {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                transform: translate(0, -50%);
                transition: $trans;
            }
        }

        &Date {
            margin-left: auto;
            color: $colorGreyDark;
            font-weight: 500;
            font-size: 13px;
        }

        &Executor {
            position: relative;
            width: 100%;
            padding-left: 15px;

            &Color {
                position: absolute;
                top: 50%;
                left: 2px;
                z-index: 2;
                width: 1px;
                height: 30px;
                border-radius: 360px 0 0 360px;
                transform: translate(0, -50%);
                transition: $trans;
            }

            &Name {
                margin-bottom: 1px;
                color: $colorDark2;
                font-size: 14px;
                line-height: 1.3;
            }

            &Type {
                color: $colorGreyDark;
                font-size: 14px;
                line-height: 1.3;
            }
        }
    }

    &__info {
        width: 100%;
        margin-bottom: -4px;
        padding-top: 14px;

        &User {
            position: relative;

            &:not(._empty) {
                padding-left: 40px;
            }

            &Avatar {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 1;
                width: 30px;
                height: 30px;
                overflow: hidden;
                border-radius: 50%;
                transform: translate(0, -50%);

                & .v2avatar {
                    background: none;
                }

                & .v2avatar__text {
                    color: inherit;
                    font-size: 13px;
                }
            }

            &Name {
                margin-bottom: 2px;
                color: $colorDark2;
                font-size: 14px;
                line-height: 1.3;
            }

            &Support {
                color: $colorGreyDark;
                font-size: 13px;
                line-height: 1.2;
            }
        }

        &Blocks {
            margin-left: auto;
        }

        &Block {
            & + & {
                margin-left: 18px;
            }

            &Support {
                margin-bottom: 5px;
                color: $colorDark2;
                font-weight: 300;
                font-size: 14px;
                line-height: 1.3;
            }

            &Value {
                align-items: center;
                height: 10px;
            }

            &Tags {
                align-items: center;
                color: $colorGreyDark;
                font-size: 8px;

                &:not(._empty) {
                    padding-left: 3px;
                }

                &._empty {
                    font-size: 12px;
                }
            }

            &Tag {
                width: 2px;
                height: 7px;
                margin-right: 4px;
                border-radius: 360px;
            }

            &Tasks {
                color: $colorDark2;
                font-size: 12px;

                &._empty {
                    color: $colorGreyDark;
                }
            }
        }
    }
}
