.v2widgetPage {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    &__scroll {
        width: 100%;
        height: 100%;
        padding: 24px;
        padding-bottom: 74px + 24px;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;
    }

    &._loading &__scroll {
        opacity: 0;
    }

    &__cards {
        align-items: stretch;
        width: 100%;
    }

    &__cardScroll {
        display: inline-flex;
        width: calc(100% + 48px);
        margin: 0 -24px;
        padding: 0 24px;
        overflow: hidden;
        overflow-x: auto;
    }

    &__card {
        width: 100%;

        &._bottom {
            margin-bottom: 12px;
        }

        &Inner {
            width: 100%;

            & + & {
                margin-top: 12px;
            }
        }

        &._contentBlogEditor {
            min-height: 500px;
        }
    }

    &._executors &__card {
        &._tags {
            width: 33%;
            height: 250px;
            margin-right: 12px;
        }

        &._comments {
            width: 35%;
            height: 250px;
            margin-right: 12px;
        }

        &._verification {
            width: calc(100% - 33% - 35% - 24px);
            height: 250px;
            margin-left: auto;
        }
    }

    &._corporation &__card {
        &._verification,
        &._group {
            width: 270px;
            margin-right: 12px;
        }

        &._tariff {
            width: 37.5%;
            margin-right: 12px;
        }

        &._limit {
            width: calc(100% - 270px - 37.5% - 12px * 2);
        }

        &._main {
            width: calc(100% - 270px - 12px);
        }
    }

    &._corporation._marginCard &__card._group &__cardInner._other {
        margin-bottom: -60px;
    }

    &._corporation._marginCard &__card._verification {
        margin-top: 60px;
    }

    &._corporationVerification &__card {
        &._verification {
            width: 270px;
            height: 270px;
            margin-right: 12px;
        }

        &._docs {
            width: auto;
            height: 270px;
        }
    }

    &._integrations &__card {
        &._beelineUsers {
            height: calc(100vh - 140px - 12px - 24px - 98px - 62px - 60px);
        }
    }

    &__foot {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding: 24px;
        background: linear-gradient(to top, $colorBlueBack 30%, rgba($colorBlueBack, 0));
        pointer-events: none;

        // border-top: 1px solid $colorBlue;

        &Actions {
            align-items: flex-end;
            margin-left: auto;
        }

        &Error {
            width: 400px;

            &Support {
                margin-bottom: 5px;
                color: $colorDark2;
                font-weight: 400;
                font-size: 15px;
            }

            &Text {
                color: $colorAlert;
                font-weight: 600;
                font-size: 15px;
                line-height: 1.3;
            }
        }

        &Button {
            width: 200px;
            pointer-events: visible;
        }
    }
}
