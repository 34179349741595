.v2notificationBar {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $colorBlueBack;
    border-radius: 24px;
    transition: $trans;

    .body__notificationsBar._showHideState:not(._show) & {
        transform: translate(calc(100% + 20px + #{$paddingContent}), 0);
    }

    .body__notificationsBar._showHideState & {
        border-radius: 0;
    }

    &__head {
        width: 100%;
        height: 62px;
        padding: 0 24px;
        border-bottom: 1px solid $colorBlue;

        &Tabs {
            width: 100%;
            height: 100%;
        }

        &Tab {
            position: relative;
            justify-content: center;
            height: 100%;

            & + & {
                margin-left: 16px;
            }

            &::after {
                position: absolute;
                bottom: -1px;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 2px;
                background: $colorMain;
                border-radius: 360px 360px 0 0;
                transition: $trans;
                content: "";
            }

            &:not(._current)::after {
                opacity: 0;
            }

            &Inner {
                @include button(0.98);
            }

            &Content {
                color: $colorGreyDark;
                font-size: 16px;
                line-height: 1.2;
                transition: $trans;

                &Counter {
                    color: $colorGreyDark;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 0.6;

                    &._parent {
                        position: relative;
                        display: inline-block;

                        &::before {
                            position: absolute;
                            top: -4px;
                            right: -4px;
                            z-index: 2;
                            width: 3px;
                            height: 3px;
                            background: $colorAlert;
                            border-radius: 50%;
                            transition: $trans;
                            content: "";
                        }
                    }

                    &:not(._active)::before {
                        opacity: 0;
                    }
                }
            }

            &._current &Content {
                color: $colorDark2;
                font-weight: 700;

                &Counter {
                    color: $colorDark2;
                }
            }
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 62px);
    }

    &__pages {
        width: 100%;
        height: 100%;
    }

    &__page {
        width: 100%;
        height: 100%;

        &:not(._show) {
            &._prev {
                transform: translate(-16px, 0);
            }

            &._next {
                transform: translate(16px, 0);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
        }

        &Head {
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 20px 20px 0;

            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 14px;
                background: linear-gradient(to bottom, $colorBlueBack, rgba($colorBlueBack, 0));
                content: "";
                pointer-events: none;
            }
        }

        &Wrapper {
            width: 100%;
            padding: 20px;
        }

        &Inner:not(._ready) &Scroll {
            opacity: 0;
        }

        &Inner._withHead &Wrapper {
            padding-top: 14px;
        }

        &Scroll {
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
        }

        &Inner._withHead &Scroll {
            height: calc(100% - 52px);
        }

        &Items {
            position: relative;
            width: 100%;

            &._static {
                display: flex;
                flex-direction: column;
            }
        }

        &Item {
            width: 100%;
        }

        &Items._static &Item:not(._last) {
            margin-bottom: 12px;
        }
    }

    &__notifyButton {
        align-items: center;
        width: calc(100% - 32px - 8px);
        height: 32px;
        padding: 6px 11px 6px 9px;
        background: #fff;
        border-radius: 10px;

        @include button(0.97);

        &Icon {
            position: relative;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            will-change: transform;

            &::before {
                position: absolute;
                top: 7px;
                left: -2px;
                z-index: 2;
                width: 16px;
                height: 1px;
                background: $colorAlert;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
                transition: $trans;
                content: "";
            }

            @include icon($colorAlert);
        }

        &._hide &Icon {
            @keyframes notifyAnimate {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(0.9);
                }

                70% {
                    transform: scale(1.1);
                }

                100% {
                    transform: scale(1);
                }
            }

            animation: notifyAnimate 0.6s 1 ease-in-out;
        }

        &:not(._hide) &Icon {
            @include icon($colorMain);

            &::before {
                background: $colorMain;
                transform: translate(-6px, -6px) rotate(45deg);
                opacity: 0;
            }
        }

        &Content {
            color: $colorDark2;
            font-weight: 300;
            font-size: 13px;
            line-height: 1.2;
            white-space: nowrap;

            & b {
                font-weight: 500;
            }
        }
    }

    &__filterButton {
        position: relative;
        width: 32px;
        height: 32px;
        margin-left: auto;
        background: #fff;
        border-radius: 10px;

        @include button(0.95);

        &Icon {
            width: 16px;
            height: 16px;
            margin: auto;
        }

        &Counter {
            position: absolute;
            top: -4px;
            right: -4px;
            z-index: 2;
            min-width: 18px;
            height: 18px;

            &._child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 2px 4px;
                color: #fff;
                font-weight: 700;
                font-size: 10px;
                text-align: center;
                background: $colorMain;
                border: 2px solid #fff;
                border-radius: 360px;
            }
        }
    }
}
