.chat {
    width: 100%;
    height: 100%;

    &__list,
    &__content {
        height: 100%;
        padding: 24px;
    }

    &__content {
        width: calc(100% - 340px);
        padding-left: 0;

        &Windows {
            width: 100%;
            height: 100%;
        }

        &Window {
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: #fff;
            border-radius: 20px;
            box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);
            transition: $trans;
        }
    }

    &__list {
        width: 340px;
        padding: 0;
    }
}
