.dealLog {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 10px 14px;
    background: rgba(#fff, 0.56);
    border: 1px solid transparent;
    border-radius: 18px;
    transition: $trans;

    &._alert {
        border-color: rgba($colorAlert, 0.56);
    }

    &._new {
        background: #fff;
    }

    &__inner {
        position: relative;
        width: 100%;
        padding-left: 42px;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 10px;
        transition: $trans;
    }

    &._alert &__icon {
        @include icon($colorAlert);
    }

    &._new &__icon {
        background: rgba($colorMain, 0.1);

        @include icon($colorMain);
    }

    &__block {
        padding-top: 5px;

        &._actions {
            align-items: flex-end;
            margin-left: auto;
            padding-right: 5px;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
    }

    &__title {
        margin-right: 6px;
        color: $colorDark2;
        font-size: 15px;

        & b {
            font-weight: 600;
            transition: $trans;
        }

        &._big {
            font-size: 22px;
        }
    }

    &._alert &__title b {
        color: $colorAlert;
    }

    &__status {
        padding: 4px 6px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        background: $colorGreyDark;
        border-radius: 6px;
    }

    &__head &__status {
        margin-right: 10px;
    }

    &__detail {
        padding: 2px 7px;
        color: $colorDark2;
        font-size: 13px;
        line-height: 1.2;
        background: #edf2f8;
        border-radius: 6px;

        & + & {
            margin-left: 6px;
        }

        & b {
            font-weight: 600;
        }
    }

    &__description {
        margin-top: 4px;
        color: $colorDark2;
        font-size: 14px;
        line-height: 1.3;

        & span {
            color: $colorGreyDark;

            &._completed {
                color: $colorSuccess;
                font-weight: 600;
            }

            &._reject {
                color: $colorAlert;
                font-weight: 600;
            }
        }

        & b {
            padding: 0 4px;
            font-weight: 500;
            background: #edf2f8;
            border-radius: 4px;
        }
    }

    &__date {
        padding-top: 4px;
        color: $colorGreyDark;
        font-size: 13px;

        &._abs {
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px 5px 0 0;
        }
    }

    &__taskStatus {
        padding: 4px 7px;
        color: $colorMain;
        font-weight: 700;
        font-size: 11px;
        white-space: nowrap;
        text-transform: uppercase;
        background: rgba($colorMain, 0.1);
        border-radius: 6px;
        transition: $trans;

        &._alert {
            color: $colorAlert;
            background: rgba($colorAlert, 0.14);
        }

        &._completed {
            color: $colorSuccess;
            background: rgba($colorSuccess, 0.14);
        }

        &._process {
            color: #ff942d;
            background: rgba(#ff942d, 0.14);
        }

        &Wrapper {
            height: 19px;
            margin-top: 7px;
        }
    }

    &__callActions {
        align-items: center;
        margin-top: 12px;
    }

    &__callFile {
        & .chatFile {
            padding: 0;
            background: none !important;
        }

        & .chatFile__audio {
            padding: 4px 0 4px 18px;
        }

        & .chatFile__audioButton {
            width: 14px;
            height: 14px;
        }

        & .chatFile__audioButtonIcon {
            width: 5px;
            height: 5px;

            &._play {
                margin-left: 0.5px;
            }
        }

        & .chatFile__audioProgressDuration {
            display: none;
        }

        & .chatFile__audioProgress {
            width: 60px;
            min-width: auto;
            padding-right: 0;

            // display: none;
        }

        & .chatFile__audioProgressLine {
            background: #edf2f8;
        }

        & .chatFile__audioProgressLineActiveDot {
            width: 5px;
            height: 5px;
        }
    }

    &__callDownload {
        width: 14px;
        height: 14px;
        margin-left: 7px;
    }
}
