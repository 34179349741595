.dealStep {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(#fff, 0.4);
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;

    &__head {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
        padding: 20px;
        background: #fff;

        &Title {
            margin-bottom: 3px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
            text-align: center;
        }

        &Counter {
            color: $colorGreyDark;
            font-size: 14px;
            text-align: center;
        }

        &Color {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 2px;
            pointer-events: none;
        }

        &Loader {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
            width: 20px;
            height: 20px;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: calc(100% - 80px);
        transition: $trans;

        &._empty {
            background: #f1f4f9;
        }

        &Inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
        }

        &._loading &Inner {
            opacity: 0;
        }

        &Support {
            position: relative;
            width: calc(100% - 40px);
            height: 29px;
            margin: 20px 0 0 20px;
            padding: 6px 6px 6px 32px;
            background: #fff;
            border-radius: 9px;

            &Icon {
                position: absolute;
                top: 9px;
                left: 12px;
                width: 12px;
                height: 12px;

                @include icon($colorGreyDark);
            }

            &Text {
                color: $colorDark2;
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }

    &__items {
        width: 100%;

        &Wrapper {
            position: relative;
            z-index: 1;
            width: 100%;
            min-height: calc(100vh - 60px - 62px - 80px - 49px);
            padding: 12px 20px 20px;
            overflow: hidden;
        }
    }

    &__item {
        width: 100%;
    }

    &__items._static &__item:not(._last) {
        margin-bottom: 12px;
    }
}
