.publicBusinessAdvantages {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 16rem 0;

    @include mob {
        padding: 12rem 0;
        overflow: hidden;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -2;
        width: calc(100% - 7rem);
        height: calc(100% - 10rem);
        background: linear-gradient(to bottom, #e3edfc, rgba(#e3edfc, 0));
        border-radius: 3rem;
        transform: translate(-50%, -50%);
        content: "";

        @include mob {
            width: calc(100% - 3rem);
            height: calc(100% - 10rem);
        }
    }

    &__inner {
        align-items: center;
        width: 100%;

        &::before {
            position: absolute;
            top: -9rem;
            left: 0;
            z-index: -1;
            width: 95rem;
            height: 95rem;
            content: "";

            @include bg("../../../../img/crm/public/business/adv-left-circle.svg", contain, left top);

            @include mob {
                width: 70rem;
                height: 70rem;
            }
        }

        &::after {
            position: absolute;
            top: 63rem;
            right: 0;
            z-index: -1;
            width: 128rem;
            height: 128rem;
            content: "";

            @include bg("../../../../img/crm/public/business/adv-right-circle.svg", contain, right top);

            @include mob {
                top: 70rem;
                width: 100rem;
                height: 100rem;
            }
        }
    }

    &__head {
        align-items: center;
        width: 40rem;
        margin-bottom: 8rem;
    }

    &__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__description {
        max-width: 100%;
        text-align: center;
    }

    &__cards {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        height: 75rem;

        @include mob {
            flex-flow: column nowrap;
            height: auto;
            padding: 0 2rem;
        }
    }

    &__cardWrapper {
        @include mob {
            width: 100%;
            margin-bottom: 2rem;
        }

        &._1,
        &._4 {
            justify-content: center;
            height: 100%;

            @include mob {
                height: auto;
            }
        }

        &._1 {
            width: 34rem;
            margin-right: 3.5rem;

            @include mob {
                width: 36rem;
            }
        }

        &._2 {
            margin-bottom: 3.5rem;

            @include mob {
                margin-bottom: 2rem;
            }
        }

        &._2,
        &._3 {
            width: 28rem;
            margin-right: 3.5rem;

            @include mob {
                width: 100%;
                margin-right: 0;
            }
        }

        &._3 {
            @include mob {
                width: 35rem;
                margin-left: auto;
            }
        }

        &._4 {
            width: 35rem;
            margin-right: 3.5rem;

            @include mob {
                width: 100%;
                margin-right: 0;
            }
        }

        &._5 {
            width: 27rem;
            margin-top: 8rem;

            @include mob {
                margin-top: 0;
            }
        }
    }

    &__cardWrapper._1 &__cardIcon,
    &__cardWrapper._4 &__cardIcon {
        height: 3.5rem;
    }

    &__card {
        width: 100%;
        padding: 4rem;
        background: #fff;
        border-radius: 4rem;

        &Icon {
            width: 4rem;
            height: 4rem;
            margin-bottom: 1.5rem;
            object-fit: contain;
            object-position: left center;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 1rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 2.6rem;
            line-height: 1.4;

            @include mob {
                font-size: 3rem;
            }
        }

        &Text {
            max-width: 100%;
            color: $colorDark2;
            font-weight: 300;
            font-size: 1.9rem;
            line-height: 1.5;

            @include mob {
                font-size: 2rem;
            }
        }

        &Support {
            margin-top: 2rem;
            color: rgba(#363c42, 0.4);
            font-weight: 600;
            font-size: 1.7rem;
            line-height: 1.5;

            @include mob {
                font-size: 1.9rem;
            }
        }

        &Link {
            display: block;
            margin-top: 2rem;
            color: $colorMain;
            font-weight: 500;
            font-size: 1.7rem;

            @include mob {
                font-size: 1.9rem;
            }
        }
    }

    &__blocks {
        width: 100%;
        margin-top: 7rem;

        @include mob {
            padding: 0 1rem;
        }
    }

    &__block {
        align-items: stretch;
        width: 100%;
        padding: 9rem;
        background: #fff;
        border-radius: 4.5rem;

        @include mob {
            flex-direction: column;
            align-items: flex-start;
            padding: 4rem 3rem;
        }

        & + & {
            margin-top: 2.5rem;

            @include mob {
                margin-top: 6rem;
            }
        }

        &Decor {
            position: relative;
            width: 50rem;

            @include mob {
                width: 100%;
                height: 34rem;
            }

            &Image,
            &Item {
                position: absolute;
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &._2 &Decor {
            @include mob {
                height: 44rem;
            }
        }

        &._3 &Decor {
            @include mob {
                display: none;
                height: 39rem;
            }
        }

        &._1 &DecorImage {
            top: -14rem;
            left: -9rem;
            width: 60rem;
            height: 60rem;
            object-fit: contain;

            @include mob {
                top: -8rem;
                left: 50%;
                width: 48rem;
                height: 48rem;
                transform: translate(-50%, 0);
            }
        }

        &._2 &DecorItem {
            z-index: 1;
            width: 55rem;
            height: 30rem;

            @include mob {
                width: 53rem;
                height: 29rem;
            }

            &._1 {
                top: -6rem;
                right: -10rem;
                z-index: 2;

                @include mob {
                    right: -14rem;
                }
            }

            &._2 {
                top: 13rem;
                right: -16rem;
                z-index: 1;

                @include mob {
                    top: 13rem;
                    right: auto;
                    left: -14rem;
                }
            }
        }

        &._3 &DecorItem {
            top: 50%;
            left: -15rem;
            z-index: 1;
            width: 72rem;
            height: 40rem;
            margin-top: -1rem;
            transform: translate(0, -50%);

            @include mob {
                top: 2rem;
                left: 50%;
                width: 60rem;
                height: 35rem;
                transform: translate(-50%, 0);
            }
        }

        &._2 &DecorItem,
        &._3 &DecorItem {
            overflow: hidden;
            background: #fff;
            border-radius: 2.5rem;
            box-shadow: 0 1.3rem 4rem rgba(#363c42, 0.12);

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                border: 0.4rem solid #fff;
                border-radius: 2.5rem;
                content: "";

                @include mob {
                    border-width: 2px;
                }
            }
        }

        &Content {
            margin-left: auto;

            @include mob {
                margin-top: 3rem;
                margin-left: 0;
            }
        }

        &._1 &Content {
            width: 66rem;

            @include mob {
                width: 100%;
            }
        }

        &._2 &Content {
            width: 68rem;
            padding-left: 3rem;

            @include mob {
                width: 100%;
                padding-left: 0;
            }
        }

        &._3 &Content {
            width: 55rem;

            @include mob {
                width: 100%;
                margin-top: 0;
            }
        }

        &:nth-child(2n) {
            flex-direction: row-reverse;

            @include mob {
                flex-direction: column;
            }
        }

        &:nth-child(2n) &Content {
            margin-right: auto;
            margin-left: 0;
        }

        &Icon {
            width: 4.5rem;
            height: 4.5rem;
            margin-bottom: 2rem;
        }

        &Title {
            margin-bottom: 2.5rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 3rem;

            @include mob {
                font-size: 3.3rem;
            }
        }

        &Text {
            max-width: 100%;
            color: $colorDark2;
            font-weight: 300;
            font-size: 1.9rem;
            line-height: 1.6;

            @include mob {
                font-size: 2rem;
            }

            & b {
                font-weight: 500;

                &._UPPER {
                    display: block;
                    margin: 2rem 0 0.5rem;
                    font-weight: 600;

                    @include mob {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    &__form {
        position: relative;
        z-index: 1;
        align-items: center;
        width: calc(100% - 8rem);
        margin: 12rem auto 0;
        padding: 13rem 8rem;
        background: linear-gradient(to bottom, #80c7ff, #4c79ff 150%);
        border-radius: 3.5rem;
        mask:
            radial-gradient(circle 2rem at top center, #0000 99%, #000) top center,
            radial-gradient(circle 2rem at bottom center, #0000 99%, #000) bottom center;
        mask-size: 100% 51%;
        mask-repeat: no-repeat;

        @include mob {
            width: calc(100% - 4rem);
            padding: 6rem 3rem;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            content: "";

            @include bg("../../../../img/crm/public/business/adv-form-back.png", cover, center);

            @include mob {
                background-repeat: repeat-y !important;
                background-position: center top;
                background-size: 300% auto;
            }
        }

        &Content {
            align-items: center;
            width: 83rem;

            @include mob {
                width: 100%;
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 3rem;
            text-align: center;
        }

        &Fields {
            flex-wrap: wrap;
            width: 100%;

            @include mob {
                flex-flow: column nowrap;
            }
        }

        &Field {
            width: calc(calc(100% / 3) - calc(2rem / 3));
            margin-right: 1rem;

            @include mob {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1.5rem;
            }

            &._email,
            &._button {
                margin-right: 0;
            }

            &._comments {
                width: calc(calc(100% / 3) * 2 - calc(1rem / 3));
                margin-top: 1rem;

                @include mob {
                    width: 100%;
                    margin-top: 0;
                }
            }

            &._button {
                margin-top: 1rem;

                @include mob {
                    margin-top: 0;
                }
            }

            &Input {
                width: 100%;
                height: 6rem;
                background: rgba(#fff, 0.15);
                border-radius: 1.5rem;

                @include mob {
                    height: 7.5rem;
                    border-radius: 2rem;
                }

                & .v2input__support,
                & .v2input__field {
                    padding: 2rem;
                    color: #fff;
                    font-size: 1.9rem;

                    @include mob {
                        padding: 2.3rem 2.5rem;
                        font-size: 2rem;
                    }
                }
            }

            &._comments &Input {
                height: 15rem;

                @include mob {
                    height: 20rem;
                }
            }

            &Button {
                width: 100%;
                height: 6rem;

                @include mob {
                    height: 7.5rem;
                }

                & .button {
                    padding: 1.5rem 2rem;
                    border-radius: 1.5rem;

                    @include mob {
                        padding: 2rem;
                        border-radius: 2rem;
                    }
                }

                & .button__content {
                    font-size: 1.9rem;

                    @include mob {
                        font-size: 2.1rem;
                    }
                }
            }

            &Info {
                margin: 1rem 0 0 0.5rem;
                color: #fff;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 1.5;

                @include mob {
                    font-size: 1.9rem;
                }

                & a {
                    color: #fff;
                    font-weight: 500;
                    border-bottom: 1px solid rgba(#fff, 0.5);
                }
            }
        }

        &Alert {
            transition: $trans;

            &._parent:not(._empty) {
                margin-top: 2rem;
            }

            &Item {
                padding: 0.8rem 1.2rem 0.9rem;
                color: #fff;
                font-weight: 500;
                font-size: 1.6rem;
                white-space: nowrap;
                background: $colorAlert;
                border-radius: 1rem;

                @include mob {
                    font-size: 2rem;
                }

                &._success {
                    background: $colorSuccess;
                }
            }
        }
    }
}
