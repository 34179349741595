.chatWindow {
    width: 100%;
    height: 100%;

    &__inner {
        width: 100%;
        height: 100%;
        transition: $trans;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__head {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 58px;
        background: #fff;
        transition: $trans;
    }

    &__tags {
        position: relative;
        z-index: 3;
        width: 100%;
        padding: 0 14px;
        background: #fff;
        transition: $trans;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 1px;
            background: #ebebeb;
            opacity: 0;
            transition: $trans;
            content: "";
        }

        &:not(._empty) {
            padding-top: 6px;
            padding-bottom: 2px;

            &::after {
                opacity: 1;
            }
        }

        &Items {
            position: relative;
            flex-wrap: wrap;
            width: 100%;
            transition: $trans;
        }
    }

    &__tags._static &__tag {
        margin: 0 4px 4px 0;
    }

    &._app &__content {
        &Inner {
            max-height: calc(100% + 20px);
            padding: calc(20px + 20px) 20px;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        transition: $trans;

        &Scroll {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: none;
            overscroll-behavior: none;
            transition: $trans;

            &._hidden {
                overflow: hidden;
            }
        }

        &:not(._ready) &Scroll {
            opacity: 0;
        }

        &Messages {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 16px 0;
            transition: $trans;

            &Item {
                width: 100%;
            }
        }

        &MessagesItem:not(._last) &Message {
            // padding-bottom: 6px;
        }

        &Message {
            position: relative;
            justify-content: flex-end;
            width: 100%;
            padding-top: 3px;
            padding-bottom: 3px;
            transition: $trans;
            will-change: transform;

            &::before {
                position: absolute;
                bottom: 10px;
                left: 12px;
                width: 4px;
                height: 4px;
                background: $colorAlert;
                border-radius: 50%;
                opacity: 0;
                transition: $trans;
                content: "";
            }

            &[data-notread]::before {
                opacity: 1;
            }

            &._type-end {
                padding-top: 14px;
                padding-bottom: 6px;
            }

            &._type-start {
                padding-top: 6px;
                padding-bottom: 14px;
            }

            &._type-message,
            &._type-comments {
                padding-right: 20px;
                padding-left: 20px;
            }

            &._inActive {
                opacity: 0.5 !important;
            }

            &._me {
                align-items: flex-end;
            }

            &._hide {
                height: 0;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                opacity: 0;
            }

            &Inner {
                width: 100%;
                transition: $trans;
            }
        }
    }

    &:not(._app)._loading &__foot {
        opacity: 0;
    }

    ._ios &__foot {
        padding-bottom: 32px !important;
    }

    &._disabled &__footInner {
        opacity: 0.5;
        pointer-events: none;
    }

    &__foot {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-top: auto;
        padding: 14px;
        border-top: 1px solid transparent;
        transition: $trans;

        @include forType("app") {
            padding: 20px;
            border: 0;
            border-radius: 24px 24px 0 0;
            box-shadow: 0 0 3px 0 rgb(54 60 66 / 20%);
        }

        @include forType("app", "light") {
            background: #fff;
        }

        @include forType("app", "dark") {
            background: #2b2f33;
        }

        @include theme("light") {
            background: #f3f3f3;
            border-color: #ebebeb;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
            border-color: $colorDarkTheme2;
        }

        @include forType("crm", "light") {
            background: #fff;
        }

        &Supports {
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 3;
            width: 100%;
            padding: 20px;
            transition: $trans;
            pointer-events: none;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Support {
            position: relative;
            z-index: 1;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 56px;
            font-weight: 600;
            font-size: 16px;
            border-radius: 12px;
            pointer-events: visible;

            @include theme("light") {
                color: $colorMain;
                background: #fcfdfe;
            }

            @include theme("dark") {
                color: #aec2ff;
                background: #2b2f33;
            }

            & + & {
                margin-top: 8px;
            }

            &Loader {
                z-index: 4;
                background: inherit;
                border-radius: 12px;
            }
        }

        &Inner {
            position: relative;
            z-index: 2;
            align-items: flex-end;
            width: 100%;
            transition: $trans;
        }

        &Actions {
            position: relative;
            z-index: 3;
            height: 36px;
            margin-left: 6px;

            @include forType("app") {
                height: 40px;
                margin-left: 8px;
            }
        }

        &Action {
            position: relative;
            z-index: 1;
            width: 36px;
            height: 36px;
            transition: $trans;

            @include forType("app") {
                width: 40px;
                height: 40px;
            }

            &._send {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &._voice {
                z-index: 2;
            }

            &._voice._active &Inner {
                background: $colorMain !important;
            }

            &._send &Inner {
                background: $colorMain !important;
            }

            &:not(._send) &Inner {
                @include forType("crm") {
                    background: $colorBlueBack;
                }

                @include forType("site", "light") {
                    background: #fff;
                }

                @include forType("site", "dark") {
                    background: $colorDarkTheme2;
                }

                @include forType("app", "light") {
                    background: $colorBlueBack;
                }

                @include forType("app", "dark") {
                    background: #3e454c;
                }
            }

            &:not(._send) + &:not(._send) {
                margin-left: 6px;

                @include forType("app") {
                    margin-left: 8px;
                }
            }

            &._disabled &Inner {
                opacity: 0.64;
                pointer-events: none;
            }

            &Inner {
                position: relative;
                z-index: 2;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                transition: $trans;

                @include forType("app") {
                    border-radius: 12px;
                    cursor: default;
                }
            }

            &._voice:not(._active) &Back {
                background: rgba($colorMain, 0);
            }

            &Back {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 100%;
                height: 100%;
                background: rgba($colorMain, 0.5);
                border-radius: 12px;
                transform: translate(-50%, -50%);
                transition: $trans;
                animation: voice 0.9s infinite ease-in-out;
                pointer-events: none;

                @keyframes voice {
                    0% {
                        transform: translate(-50%, -50%) scale(1);
                    }

                    100% {
                        transform: translate(-50%, -50%) scale(1.6);
                        opacity: 0;
                    }
                }

                @include forType("app") {
                    animation: appVoice 1.2s infinite ease-in-out;

                    @keyframes appVoice {
                        0% {
                            transform: translate(-50%, -50%) scale(1);
                        }

                        100% {
                            transform: translate(-50%, -50%) scale(1.7);
                            opacity: 0;
                        }
                    }
                }

                &._1 {
                    animation-delay: 0s !important;
                }

                &._2 {
                    animation-delay: 0.3s !important;
                }

                &._3 {
                    animation-delay: 0.6s !important;
                }

                &._4 {
                    animation-delay: 0.9s !important;
                }
            }

            &Icon {
                display: block;
                width: 20px;
                height: 20px;
                margin: auto;

                @include icon(#b5bdc5);

                @include forType("app") {
                    width: 24px;
                    height: 24px;
                }
            }

            &._voice._active &Icon {
                @include icon(#fff !important);
            }

            &._send &Icon {
                width: 16px;
                height: 16px;

                @include forType("app") {
                    width: 20px;
                    height: 20px;
                }
            }

            &._send &Icon {
                @include icon(#fff);
            }

            &Loader {
                background: $colorMain;
                border-radius: inherit;

                & ._LOADERITEM {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    &__area {
        position: relative;
        flex: 2 auto;
        background: #fff;
        background: $colorBlueBack;
        border-radius: 10px;
        transition: $trans;

        @include forType("app") {
            width: calc(100% - 96px);
            border: 1px solid #a3abb3;
            border-radius: 12px;

            &._voices {
                border-color: transparent;
            }
        }

        &._active {
            border-color: $colorMain;
        }

        &Voice {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            transition: $trans;

            @include forType("app") {
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
            }

            &:not(._show) {
                opacity: 0;
            }
        }

        &Input {
            width: 100%;
        }

        &Emodzi {
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 2;
            width: 16px;
            height: 16px;
            transition: $trans;

            @include button(0.96);
        }
    }

    &__date {
        align-items: center;
        width: 100%;
        padding: 12px 0;

        &Inner {
            padding: 6px 12px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 14px;
            border-radius: 360px;

            @include theme("light") {
                color: #9da8b4;
                background: #fff;
            }

            @include theme("dark") {
                color: $colorDarkThemeText;
                background: $colorDarkTheme3;
            }

            @include forType("crm", "light") {
                background: $colorBlueBack;
            }

            @include forType("app", "light") {
                background: #fff;
            }
        }
    }

    &__files {
        position: relative;
        z-index: 1;
        width: calc(100% + 8px);
        margin-left: -4px;
        transition: $trans;

        &:not(._empty) {
            margin-top: -6px;
            padding-bottom: 4px;
        }

        &._static {
            display: flex;
            flex-flow: row wrap;
        }

        &Item {
            width: 25%;
            padding: 4px;

            &._mode-popup {
                width: calc(100% / 3);
            }

            @include forType("app") {
                width: 50%;
            }
        }
    }

    &__file {
        position: relative;
        width: 100%;
        transition: $trans;

        &Delete {
            position: absolute;
            top: -6px;
            right: -6px;
            z-index: 2;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 2px 0 rgb(173 183 197 / 64%);

            &Icon {
                width: 12px;
                height: 12px;
                margin: auto;

                @include icon($colorAlert);
            }
        }
    }

    &__session {
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 9px 0;
        text-align: center;
        background: $colorBlueBack;

        @include forType("app", "light") {
            background: #fff;
        }

        @include forType("app", "dark") {
            background: #2b2f33;
        }

        &Inner {
            color: $colorGreyDark;
            font-size: 12.5px;

            @include forType("app", "light") {
                color: $colorGreyDark;
            }

            @include forType("app", "dark") {
                color: $colorGreyDark;
            }

            & b {
                font-weight: 600;
            }
        }
    }
}
