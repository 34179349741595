.v2notification {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 16px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 2px rgb(62 69 76 / 12%);

    &._shake {
        animation: shake 0.8s 1 ease-in-out;
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
    }

    &__subTitle {
        color: #ff9700;
        font-weight: 600;
        font-size: 10px;
        line-height: 1.2;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    &._notRead &__subTitle {
        color: $colorMain;
    }

    &._important &__subTitle {
        color: $colorAlert;
    }

    &__date {
        margin-left: auto;
        color: $colorGreyDark;
        font-weight: 500;
        font-size: 12px;
    }

    &__title {
        max-width: 100%;
        margin-bottom: 1px;
        color: $colorDark2;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
    }

    &__description {
        max-width: 100%;
        margin-bottom: 12px;

        &Inner {
            color: $colorDark;
            font-weight: 300;
            font-size: 13px;
            line-height: 1.5;

            & b {
                font-weight: 500;
            }

            & span {
                font-weight: 300;
            }
        }

        &Corporation {
            display: block;
            margin-top: 4px;
        }
    }

    &__link {
        margin-top: 5px;
        color: $colorMain;
        font-weight: 500;
        font-size: 13px;

        @include button(0.97);

        &Icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: 3px;
            transform: translate(0, 3px);
        }
    }

    &._read &__link {
        color: $colorGreyDark;

        @include icon($colorGreyDark);
    }

    &__buttons {
        width: 100%;
    }

    &__button {
        & .v2button {
            padding: 9px 16px 10px;
        }

        & + & {
            margin-left: 8px;
        }
    }

    &__info {
        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 2;
        align-items: center;
    }

    &__new {
        margin-right: 4px;
        color: $colorMain;
        font-size: 13px;
    }

    &__view {
        position: relative;
        padding-left: 18px;
        color: $colorGreyDark;
        font-size: 14px;

        &Icon {
            position: absolute;
            top: 50%;
            left: 0;
            width: 14px;
            height: 14px;
            transform: translate(0, -50%);

            @include icon(rgba($colorGreyDark, 0.64));
        }
    }
}
