.v2popup {
    width: 100%;
    height: 100%;
    padding: 64px 0;
    overflow: auto;

    &._public {
        padding: 6rem 0;
    }

    &._mob {
        padding: 0;
        overflow: hidden;
    }

    &._full {
        padding: 0;
    }

    &__inner {
        width: 600px;
        margin: auto;
        background: #fff;
        border-radius: 28px;
        transition: $trans;
        will-change: transform;
    }

    &._full &__inner {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
    }

    ._POPUPWRAPPER._pin &._galeryPopup,
    ._POPUPWRAPPER._chats &._galeryPopup {
        padding: 0;
        pointer-events: none;
    }

    ._POPUPWRAPPER._pin &._galeryPopup &__inner,
    ._POPUPWRAPPER._chats &._chatsPopup &__inner {
        position: relative;
        width: 500px;
        height: 500px;
        margin: 0;
        border-radius: 28px;
        box-shadow: 0 0 24px rgba(#000, 0.1);
        pointer-events: visible;

        & .v2widget {
            background: none;
            user-select: none;
        }

        & .v2widget__headInfo,
        & .v2widget__headActions {
            pointer-events: none;
        }

        & .v2widget__headActionsElem {
            pointer-events: visible;
        }
    }

    ._POPUPWRAPPER._chats &._chatsPopup &__inner {
        width: 420px;
        overflow: hidden;
    }

    &._disabled &__inner {
        transition: none;
    }

    ._POPUPWRAPPER:not(._show) &:not(._full) &__inner {
        transform: scale(0.98);
    }

    ._POPUPWRAPPER:not(._show) &._full &__inner {
        transform: scale(1.02);
    }

    &._alertPopup &__inner,
    &._filterPopup &__inner {
        width: 360px;
    }

    &._payPopup &__inner,
    &._joinContractPopup &__inner,
    &._payGroupUpload &__inner,
    &._chatEditPopup &__inner,
    &._dealTriggerPopup &__inner,
    &._taskPopup &__inner,
    &._dealGroupCustomFieldsPopup &__inner,
    &._dealCallPopup &__inner {
        width: 420px;
    }

    &._corporationEditPopup &__inner,
    &._corporationAmountDocPopup &__inner,
    &._userPopup &__inner,
    &._rolePopup &__inner,
    &._docPopup &__inner,
    &._walletPopup &__inner,
    &._executorMvdPopup &__inner,
    &._executorLimitsPopup &__inner,
    &._payGroupPopup &__inner,
    &._integrationPopup &__inner,
    &._beelineUserPopup &__inner,
    &._dealGroupPopup &__inner,
    &._dealPopup &__inner {
        width: 380px;
    }

    &._chatTemplatePopup &__inner,
    &._chatMailingPopup &__inner {
        width: 500px;
    }

    &._calendarPopup &__inner {
        width: 340px;
    }

    &._logsPopup &__inner {
        width: 860px;
    }

    &._chatTemplatesPopup &__inner {
        width: 860px;
    }

    &._roleSectionPopup &__inner {
        width: 860px;
    }

    &._corporationCreatePopup &__inner {
        width: 640px;
    }

    // &._galeryPopup &__inner {
    //     width: 860px;
    //     overflow: hidden;
    // }

    &._paySignPopup &__inner,
    &._contractSignPopup &__inner {
        width: 720px;
    }

    &._chatExecutorDocPopup &__inner {
        width: 460px;
    }

    &._callPopup &__inner {
        width: 89rem;
    }

    &__head {
        position: relative;
        align-items: flex-end;
        width: 100%;
        padding: 24px 28px;
        padding-right: 36px;
        border-bottom: 1px solid $colorBlue;

        &._COL {
            align-items: flex-start;
        }

        &._ROW._center {
            align-items: center;
        }

        &Actions {
            margin-left: auto;
        }
    }

    &__close {
        position: absolute;
        top: 50%;
        right: 28px;
        width: 22px;
        height: 22px;
        transform: translate(0, -50%);

        &._top {
            top: 26px;
            right: 28px;
            transform: translate(0, 0);
        }

        &:hover {
            @include hover {
                @include icon($colorMain);
            }
        }
    }

    &__tabs {
        width: 100%;
        margin-top: 20px;
        margin-bottom: -24px;
    }

    &__tab {
        display: block;

        & + & {
            margin-left: 16px;
        }

        &Input {
            display: none;
        }

        &Inner {
            position: relative;
            padding-bottom: 18px;
            color: $colorDark2;
            font-size: 15px;
            transition: $trans;

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $colorMain;
                opacity: 0;
                transition: $trans;
                content: "";
            }
        }

        &Input:checked ~ &Inner {
            color: $colorMain;
            font-weight: 600;

            &::after {
                opacity: 1;
            }
        }
    }

    &._filterPopup &__content {
        max-height: calc(100vh - 128px - 75px);
    }

    &._chatMailingPopup &__content,
    &._chatTemplatePopup &__content {
        max-height: calc(100vh - 128px - 75px - 103px);
    }

    &._dealGroupCustomFieldsPopup &__content {
        max-height: calc(100vh - 128px - 120px - 103px);
    }

    &._knowledgeSettingsPopup &__content {
        max-height: calc(100vh - 128px - 120px - 103px);
    }

    &__content {
        width: 100%;
        max-height: calc(100vh - 64px - 75px);
        overflow: hidden;
        overflow-y: auto;
        border-radius: 0 0 28px 28px;

        &._padding {
            padding: 28px;
        }

        &Wrapper {
            width: 100%;
            transition: $trans;
        }

        &Inner {
            width: 100%;
            padding: 28px;
            transition: $trans;

            &._hide {
                opacity: 0;
            }
        }
    }

    &__icon {
        width: 36px;
        height: 36px;
        margin-bottom: 6px;
        object-fit: contain;
    }

    &._alertPopup &__icon {
        width: 36px;
        height: 36px;
        margin-bottom: 6px;

        &._important {
            @include icon($colorAlert);
        }
    }

    &._alertPopup &__content._newVersion &__icon {
        width: 60px;
        height: 60px;
        margin-bottom: 6px;
    }

    &__title {
        color: $colorDark2;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.3;

        &Inner {
            height: 20px;
            color: $colorDark2;
            font-weight: 300;
            font-size: 20px;
            line-height: 1.3;
            white-space: nowrap;

            &._parent {
                display: inline-block;
                line-height: 0.7;
            }
        }
    }

    &__dynamicDescription {
        width: 100%;
        color: $colorDark2;
        font-weight: 300;
        font-size: 15px;
        line-height: 1.3;

        &._parent {
            margin-top: 6px;
        }
    }

    &__description {
        margin-top: 6px;
        color: $colorDark2;
        font-weight: 300;
        font-size: 15px;
        line-height: 1.3;
        transition: $trans;

        &._hidden {
            opacity: 0;
        }

        &._bottom {
            margin-bottom: 12px;
        }

        &._bigSize {
            font-size: 16px;
        }
    }

    &._chatTemplatesPopup .v2widget,
    &._chatTemplatesPopup .v2widget__content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__checkbox {
        &._top {
            margin-top: 16px;
        }
    }

    &__field {
        width: 100%;

        &._checkbox {
            width: auto;
            margin-left: -12px;
        }

        &._bottom {
            margin-bottom: 16px;
        }

        &._top {
            margin-top: 16px;
        }
    }

    &__textMessage {
        width: 100%;
        height: 120px;
        margin-top: 12px;
        background: $colorBlueBack;
        border-radius: 10px;
    }

    &__foot {
        width: 100%;

        &._top {
            margin-top: 16px;
        }
    }

    &__button {
        width: 100%;
    }

    &__cancel {
        position: relative;
        margin-top: 14px;
        color: $colorAlert;
        font-weight: 600;
        font-size: 15px;

        @include button;

        &Loader {
            position: absolute;
            top: 50%;
            left: 100%;
            width: 16px;
            height: 16px;
            margin-top: 1px;
            margin-left: 6px;
            transform: translate(0, -50%);

            @include loader($colorAlert);
        }
    }

    &__blocks {
        width: 100%;
    }

    &__block {
        width: 100%;

        &:not(._show) {
            &._prev {
                transform: translate(-20px, 0);
            }

            &._next {
                transform: translate(20px, 0);
            }
        }
    }

    &__upload {
        width: 100%;
        margin-top: 16px;
    }

    // &__error{
    //     &._parent:not(._empty){
    //         mt
    //     }
    // }

    &._public &__mob {
        &::before {
            width: 7rem;
            height: 0.4rem;
            margin-bottom: 1.5rem;
            border-radius: 36rem;
        }

        &Box {
            padding: 3.2rem 2.8rem 2.8rem;
            border-radius: 3.2rem 3.2rem 0 0;
        }
    }

    &__mob {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-top: auto;
        pointer-events: visible;
        will-change: transform;

        &::before {
            position: absolute;
            bottom: 100%;
            left: 50%;
            z-index: 2;
            width: 40px;
            height: 3px;
            margin-bottom: 12px;
            background: rgba(#000, 0.2);
            border-radius: 360px;
            transform: translate(-50%, 0);
            transition: $trans;
            content: "";
        }

        &Box {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 26px 20px 20px;
            overflow: hidden;
            overflow-y: auto;
            background: #fff;
            border-radius: 28px 28px 0 0;
            transition: $trans;
            will-change: transform;
        }

        &Inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }

    .body__v2popup:not(._show) &__mob {
        &::before {
            opacity: 0;
        }
    }
}
